import { ValueOf } from 'type-fest';

import { Country } from '@/domain/address/types';
import { ShopType } from '@/domain/shop/types';
import { UserPrivate } from '@/domain/user/types';

export const EUserType = {
  Buyer: 'buyer',
  Seller: 'seller',
} as const;

export type UserType = ValueOf<typeof EUserType>;

export const EProfileType = {
  Individual: 'individual',
  ProfessionalBuyer: 'professional-buyer',
  ProfessionalSeller: 'professional-seller',
} as const;

export type ProfileType = ValueOf<typeof EProfileType>;

export const EJobType = {
  Architect: 'architect',
  Company: 'company',
  Hotel: 'hotel',
  Liberal: 'liberal',
  Medical: 'medical',
  Other: 'other',
  RealEstate: 'real_estate',
  Restaurant: 'restaurant',
  Shop: 'shop',
} as const;

export type JobType = ValueOf<typeof EJobType>;

export interface AuthenticatedUser extends UserPrivate {
  token: string;
}

export interface LoginPayload {
  password: string;
  username: string;
}

export interface LoginApplePayload {
  email?: string;
  firstName?: string;
  lastName?: string;
  token: string;
}

export interface RegisterPayload {
  activity?: UserType | null;
  appleId?: string;
  appleToken?: string;
  businessId?: string;
  country?: Country['code'];
  email: string;
  firstName: string;
  googleId?: string;
  googleToken?: string;
  job?: JobType | null;
  lastName: string;
  newsletter?: boolean;
  password: string;
  type?: ShopType;
}

export interface RegisterProfile {
  payload: Pick<RegisterPayload, 'activity' | 'type'>;
  type: ProfileType;
}

export type ShopUpdatePayload = Pick<RegisterPayload, 'activity' | 'businessId' | 'job' | 'type'>;
